import React, { useEffect } from 'react'
import c3 from "c3";
function CXBarChartSm(props) {
    console.log("CX Bar chart small",props)
    const { height, width, id } = props
    const keys = [...props.keys]
    const values = [...props.values];
    let id2 = `kpiChart${id}`;
    let csTrendVals = {
        x: 'x',
        columns:
        [
          ['x', ...keys],
          ['score', ...values]
        ],
        type: 'bar',
        labels: false,
        colors: {
            'score': 'grey'
        }
      }
    useEffect(() => {
        c3.generate({
            bindto: `#kpiChart${id}`,
            size: {
                height: height
               // width: width
            },
            data: csTrendVals,
            axis: {
                x: {
                    show: false,
                    type: 'category',
                    // height: 180
                    tick: {
                        rotate: -50,
                        multiline: false
                    },
                    width: 100,
                },
                y:{
                    show: false
                }
                
            },
            bar: {
                width: {
                    ratio: 0.7 // this makes bar width 50% of length between ticks
                },
                // or
                // width: 100 // this makes bar width 100px
            },
            legend: {
                show: false
            },
            padding: {
                bottom: 0 //adjust chart padding bottom
                // bottom: 20 //adjust chart padding bottom
            }
            // size: {
            //    height:height
            //   }
        });
    })

    return (
        <div id={id2} />
    )
}
export default CXBarChartSm