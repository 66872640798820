import React from 'react'
import ListItem from './listItem';
import styles from './listItemWrapper.module.scss'


function ListItemWrapper(props) {
const {list, name, title, type} = props;
console.log("List Item Wrapper --props",props)
return (
    <div className={styles.itemWrapper}>
        
       {Array.isArray(list) && list.length>0 && list.map( (item, index)=>
          <ListItem key={item.id} item={item}/> 
        ) 
       } 
    </div>
)
}

export default ListItemWrapper