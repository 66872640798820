import React from "react";
import styles from "../tiles.module.scss";
import Chevron from "../../../../components/chevron/Chevron";
import * as utils from '../../../../utils/utils';
import CXBarChartSm from '../../../../components/chart-sm/Chart-sm';

const CXScore = (props) => {
    const { cxScore, cxData} = props;
    console.log("CXSCORE--BOX",cxScore)
    const {id, score, quarterly, difference, cxKeys, cxValues} = cxData

    //const [setActive, setActiveState] = useState("active");
    //const [setRotate, setRotateState] = useState("accordion__icon rotate");
//    setRotateState(
//     setActive === "active" ? "accordion__icon rotate" : "accordion__icon"
//   );
    return (
        <div
            className={styles.cxCardContainer}
            onClick={() => props.viewScore('cx')}
        >
            <div className={styles.cxCardHeader}>
                {/*<div className={styles.cardTitle}>CX Score</div>*/}
                { (cxScore.dataPoints !== undefined)?
                <div className={styles.nValue}><span className={styles.tooltipForCX}>Total number of order lines considered for calculation of overall CX Score in this quarter</span>n = { utils.convertToInternationalCurrencySystem(cxScore.dataPoints)}</div>:''
                }
            </div>
            <div className={styles.cxCardBody}>
                <div className={styles.cardTitle}>CX Score</div>
                <div className={styles.progressValBody}>
                    {
                        !cxScore.score ? `-` : <span className={`${styles.progressVal} ${Number(cxScore.score).toFixed(0)!== Number(cxScore.lastQuarterValue).toFixed(0)? Number(cxScore.score).toFixed(0) > Number(cxScore.lastQuarterValue).toFixed(0) ? 'text-color-green' : 'text-color-red': 'text-color-yellow' }`}>{Number(cxScore.score).toFixed(0)}<span className={`${styles.chevron} `}><Chevron className={`accordion__icon ${Number(cxScore.score).toFixed(0) <  Number(cxScore.lastQuarterValue).toFixed(0)   ? styles.rotate : null}`} width={16} height={15} fill={"#000"} /></span></span>
                    }
                    
                    <span className={styles.indexDesc}>
                        {
                           Number(cxScore.score).toFixed(0) !== Number(cxScore.lastQuarterValue).toFixed(0) ? Number(cxScore.score).toFixed(0) > Number(cxScore.lastQuarterValue).toFixed(0) ?<span>+</span> : <span>-</span>: <span className={styles.zeroPadding}>{``}</span>
                        }
                        <span>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span>
                                
                    </span>
                </div>
                {<div className={styles.cxScoreTrendingChart}>
                    <CXBarChartSm 
                                id= {10}
                                height={70}
                                width={100}
                                keys={cxKeys}
                                values={cxValues}></CXBarChartSm>
                </div>}
                {/*<div className={styles.myProgress}>
                    <div className={cxScore.score >= cxScore.benchmark ? `${styles.myBar} bg-green` : `${styles.myBar} bg-red`} style={{ width: `${cxScore.score}%` }}></div>
                </div>*/}
                {/*
                    Number(cxScore.score).toFixed(0) === 0 ? <span className={styles.indexDesc}>No transactions in current quarter</span> :
                        Number(cxScore.score).toFixed(0) === Number(cxScore.lastQuarterValue).toFixed(0) ? <div className={styles.indexDesc}>
                            <span>Same as last quarter</span>
                            <span className={styles.arrowNoChange}></span>
                        </div>
                            :
                            <div className={styles.indexDescs}>
                                +<span>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span>
                                {<span className={cxScore.score > cxScore.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>}
                            </div>*/
                }
            </div>
        </div>
    )
}
export default CXScore;